import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { TravelHeader } from '@entur/travel';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { colors } from '@entur/tokens';
import ex1 from './TravelHeaderExample.png';
import { travelheader } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<TravelHeader from=\"Bergen\" to=\"Oslo\"></TravelHeader>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      TravelHeader,
      Playground,
      PageHeader,
      ImportStatement,
      colors,
      ex1,
      travelheader,
      DefaultLayout,
      _frontmatter
    }} props={travelheader} mdxType="Playground">
  <TravelHeader from="Bergen" to="Oslo" mdxType="TravelHeader"></TravelHeader>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "travelheader"
    }}>{`TravelHeader`}</h3>
    <ImportStatement imports="TravelHeader" mdxType="ImportStatement" />
    <Props of={TravelHeader} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Destinasjonene skal alltid stå på hver sin linje, og ‘til’-destinasjon står alltid med et innrykk etter streken på linje to.
Hvis det er så lange stedsnavn at de ikke får plass, benyttes ‘…’ isteden for å brekke til ny rad.
‘Fra’ iog ‘Til’ er alltid stoppesteder, men på billetter kan også soner benyttes.`}</p>
    <h3 {...{
      "id": "eksempel"
    }}>{`Eksempel`}</h3>
    <p>{`Her er et eksempel på hvordan man har tatt i bruk travel header i reiseplanleggeren til Entur.`}</p>
    <img src={ex1} style={{
      border: `2px solid  ${colors.greys.grey80}`
    }} alt="Skjermbilde av Entur-appen hvor Travel-Header illustrerer avreisested og destinasjon" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      